import React from 'react';

import PharmuniLogo from '@core/assets/images/pharmuni-logo-circle.svg';
import TrustPilotStars from '@core/assets/images/stars-4.5.svg';
import TrustPilotVerifiedCompnay from '@core/assets/images/verified-company-trust-pilot.svg';

import classes from './TrustPilot.module.scss';
import LinkButton from '@core/components/linkbutton/LinkButton';

const TrustPilot = () => {

    return (

        <div className={classes['wrapper']}>
            <LinkButton kind='simple' href={'https://www.trustpilot.com/review/pharmuni.com'}>
                <div className={`d-flex flex-wrap justify-content-center align-items-center gap-3`}>
                    <PharmuniLogo />
                    <div>
                        <h3>Pharmuni</h3>
                        <h5>Reviews 9  •  Excellent</h5>
                        <div className='d-flex align-items-center gap-2'>
                            <TrustPilotStars />
                            <span>4.3</span>
                        </div>
                        <div className={classes['verify-company']}>
                            <TrustPilotVerifiedCompnay />
                            <span>VERIFIED COMPANY</span>
                        </div>
                    </div>
                </div>
            </LinkButton>
        </div>

    );
};

export default TrustPilot;